(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name phases.newPhase.controller:NewPhaseCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.phases.newPhase')
    .controller('NewPhaseCtrl', NewPhaseCtrl);

  function NewPhaseCtrl(Phases, LocalPhases, $mdToast, $state, $filter, AclService, townHalls) {
    var vm = this;
    vm.ctrlName = 'NewPhaseCtrl';
    vm.can = AclService.can;
    vm.townHalls = townHalls;

    vm.phase = {};

    function successMethod() {
      $state.go('home.phases', {}, {reload: true});
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('PHASES.PHASE_SUCCESSFUL_CREATE'))
          .position('bottom left')
          .hideDelay(3000)
      );
    }

    function errorMethod(error) {
      var errorMessage;
      if (error.status === 409) {
        errorMessage = 'PHASES.ERROR_INVALID_DATE_PHASE';
      } else {
        errorMessage = 'SERVER_ERROR';
      }
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')(errorMessage))
          .position('bottom left')
          .hideDelay(3000)
      );
    }

    // TODO: Change API call to different routes and control acl permissions to call one or another
    vm.savePhase = function () {
      vm.phase.iniDate = moment(vm.iniDate).format('YYYY-MM-DD');
      vm.phase.endDate = moment(vm.endDate).format('YYYY-MM-DD');
      if (vm.iniDate > vm.endDate) {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('ERROR_INVALID_DATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
      } else {
        if (vm.phase.type === 'regional') {
          Phases.save(vm.phase, successMethod, errorMethod);
        }
        else {
          var townHallId = angular.isDefined(vm.selectedTownHall) ? vm.selectedTownHall.id : townHalls[0].id;
          LocalPhases.save({id: townHallId}, vm.phase, successMethod, errorMethod);
        }
      }
    };
  }
}());

